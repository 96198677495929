import { FaStar } from "react-icons/fa";

const StarRating = ({ value, updateState }) => {
  return (
    <div className="d-flex gap-2 ">
      {[1, 2, 3, 4, 5].map((star) => (
        <FaStar
          key={star}
          size={30}
          className={`c-pointer transition-colors duration-300 ${
            star <= value ? "text-warning" : "text-secondary"
          }`}
          onClick={() => updateState(star)}
        />
      ))}
    </div>
  );
};

export default StarRating;
