import auth from "./AuthAPI.js";
import Http from "./Http.js";
import * as APPCONFIG from "../config/app-config.js";
import api from "./BaseAPI.js";
class MyYuukke {
  searchUsers(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/searchUsers`,
      data: formValues,
    });
  }
  searchUsersV2(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getFriendsDetailsFilterV2`,
      data: formValues,
    });
  }

  searchAllUsers(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/getSearchUserByType`,
      url: `${APPCONFIG.API_URL}api/connection_filter/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  //exit group
  exitGroup(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/exitGroup`,
      data: formValues,
    });
  }
  //remove user in this group
  removeUser(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/removeUserInGroup`,
      data: formValues,
    });
  }
  //getRequestById
  getRequestById(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getRequestbyId`,
      data: formValues,
    });
  }
  //RequestSubjects
  RequestSubject(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/requestedSubject`,
      data: formValues,
    });
  }
  //RequestRejectedSubjects
  RequestSubjectRejected(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/rejectedSubjectRequest`,
      data: formValues,
    });
  }
  deleteSubjectById(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deleteSubjectById`,
      data: JSON.stringify(formValues),
    });
  }

  //acceptSubjectRequest
  AcceptSubject(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/acceptedSubject`,
      data: formValues,
    });
  }
  //getAllMemberDetails
  getAllMembersDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/group/memberFilter`,
      // url: `${APPCONFIG.API_URL}api/getAllMembersDetailsByType`,
      data: formValues,
    });
  }

  //getAllRequestSubjects
  getRequestedSubjectsList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getRequestedSubjectsList`,
      data: formValues,
    });
  }
  storeCallScheduleDetails(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/saveCallSchedule`,
      data: formValues,
      config,
    });
  }

  getMembersCount(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/membersCount`,
      data: formValues,
    });
  }

  getCallScheduleDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getallcallScheduleById`,
      data: JSON.stringify(formValues),
    });
  }

  saveMemberList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveOrUpdateMember`,
      data: JSON.stringify(formValues),
    });
  }

  getuserAvailabilities(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getuserAvailabilities`,
      data: JSON.stringify(formValues),
    });
  }

  bookSchedule(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/personToPersonCallSchedule`,
      data: JSON.stringify(formValues),
    });
  }
  bookFriendSchedule(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/personToFriendCallSchedule`,
      data: JSON.stringify(formValues),
    });
  }
  updatePeerSchedule(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updatePeerSchedule`,
      data: JSON.stringify(formValues),
    });
  }
  cancelSchedule(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/personToPersonCallSchedule`,
      data: JSON.stringify(formValues),
    });
  }

  getMemberList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getMemberDetailsById`,
      data: JSON.stringify(formValues),
    });
  }

  saveCompetency(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveCompetency`,
      data: formValues,
    });
  }
  getJoinCallData(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/meetingJoinCall`,
      data: formValues,
    });
  }

  sendRequest(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/sendrequestOpenGroupMeeting`,
      data: formValues,
    });
  }

  rejectMeeting(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/upcommingCallsReject`,
      data: formValues,
    });
  }
  getGroupDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getGroupDetailsById`,
      data: formValues,
    });
  }

  getPeerGroupDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getPeerScheduleById`,
      data: formValues,
    });
  }

  rescheduleCallScheduleDetails(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateGroupSchedule`,
      data: formValues,
      config,
    });
  }

  createCallSchedule(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createGroupSchedule`,
      data: formValues,
      config,
    });
  }

  saveCallSchedule(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveCallSchedule`,
      data: formValues,
      config,
    });
  }

  groupUpdate(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/groupUpdate`,
      data: formValues,
      config,
    });
  }

  deleteGroup(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/groupDelete`,
      data: formValues,
    });
  }
  getAlltypeofCommunity(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAllCommunity`,
      data: formValues,
    });
  }
  admin_resource_post_delete(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/admin_resource_post_delete`,
      data: formValues,
    });
  }
  requestOrAcceptOrReject(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/group/requestOrAcceptOrReject`,
      data: formValues,
    });
  }
  // getCallHistory(formValues)
  // {
  //       return auth({
  //     method: 'post',
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/callSchedulerHistory`,
  //     data: formValues
  //   })
  // }
  // getOpenGroupMeeting(formValues)
  // {
  //       return auth({
  //     method: 'post',
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/getOpenGroupMeeting`,
  //     data: formValues
  //   })
  // }
  // openGroupRequestAccept(formValues)
  // {
  //       return auth({
  //     method: 'post',
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/openGroupRequestAccept`,
  //     data: formValues
  //   })
  // }
  // openGroupRequestReject(formValues)
  // {
  //       return auth({
  //     method: 'post',
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/openGroupRequestReject`,
  //     data: formValues
  //   })
  // }
  // getCallScheduleDetails(formValues) {
  //       return auth({
  //     method: 'post',
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/getallcallScheduleById`,
  //     data: JSON.stringify(formValues)
  //   })
  // }

  // commonCountryList() {
  //       return auth({
  //     method: 'get',
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/commonList`,
  //   })
  //}
  // filterCallSchedulerGroup(formValues) {
  //       return auth({
  //     method: 'post',
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/filterCallSchedulerGroup`,
  //     data: JSON.stringify(formValues)
  //   })
  // }
  // getAllRequest(formValues) {
  //       return auth({
  //     method: 'post',
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/getAllRequest`,
  //     data: JSON.stringify(formValues)
  //   })
  // }
  // CallScheduleAccept(formValues) {
  //       return auth({
  //     method: 'post',
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/CallScheduleAccept`,
  //     data: JSON.stringify(formValues)
  //   })
  // }
  // CallScheduleReject(formValues) {
  //       return auth({
  //     method: 'post',
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/CallScheduleReject`,
  //     data: JSON.stringify(formValues)
  //   })
  // }
  // bookUserAvailabilities(formValues) {
  //       return auth({
  //     method: 'post',
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/userAvailable`,
  //     data: JSON.stringify(formValues)
  //   })
  // }

  //getReportEmailByAdmin
  getReportEmail(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getReportEmailByAdmin`,
      data: formValues,
    });
  }

  shareChallengeApplied(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/shareChallengeApplied`,
      data: formValues,
    });
  }
  getResourcePlayList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getResourcePlayList`,
      data: formValues,
    });
  }
  getResourceByPlaylist(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getResourceByPlaylist`,
      data: formValues,
    });
  }
  work_space_user_list(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/work_space_user_list/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  work_space_join_or_unjoin(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/work_space_join_or_unjoin/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  not_work_space_user_list(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/not_work_space_user_list/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  work_space_save_or_update(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/work_space_save_or_update`,
      data: formValues,
      config,
    });
  }
  work_space_add_user(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/work_space_add_user/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  post_view(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/post_view`,
      data: formValues,
    });
  }
  csv_work_space_add_user(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/csv_work_space_add_user/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  update_work_space_details(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/update_work_space_details`,
      data: formValues,
      config,
    });
  }
  askHelpDelete(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/askHelp/delete`,
      data: JSON.stringify(formValues),
    });
  }

  isAdmin(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/group/isAdmin`,
      data: formValues,
    });
  }
  getEventList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/event-details/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  getEventVideoList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getEventVideo`,
      data: formValues,
    });
  }
  getUserSlots() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/get_user_availability/v2`,
      url: `${APPCONFIG.API_URL}api/v4/get_user_availability`,
    });
  }
  saveUserSlots(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveEventName/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  saveUserDateSlot(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveEventDatesandTimes`,
      data: formValues,
    });
  }
  getSlotDetailsById(slug) {
    return api({
      method: "get",
      url: `${APPCONFIG.API_URL}auth/get_slots/${slug}`,

      // url: `${APPCONFIG.API_URL}auth/getTimeslotByToken/${slug}`,
    });
  }
  saveUserBookingSlots(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/save_user_booking_slots/v2`,
      data: formValues,
    });
  }
  getSlotBasedTimeSlot(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getEventDateList`,
      data: formValues,
    });
  }
  deleteSlotBasedOnTime(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deleteTimeSlotandDate`,
      data: formValues,
    });
  }
  getRequestList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v2/booking-slot`,
      data: formValues,
    });
  }
  saveUserAvailability(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/save_user_availability/v2/${Http.WORK_SPACE()}`,
      // }api/save_user_availability/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  updatePreference(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/v2/save_calendar_preference/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  saveCalendarSchedule(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/calendar_schedule`,
      data: formValues,
    });
  }
  savePaymentInforMation(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/payment/v2/save_payment_details`,
      data: formValues,
    });
  }
  subscriptionList(slug = "service") {
    return api({
      method: "get",
      // headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/v2/subscription/list`,
      url: `${APPCONFIG.API_URL}auth/v2/subscription-by-type/${slug}`,

      // data: formValues,
    });
  }
  addUserSubscription(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/subscription/v2/add_user_subscription`,
      data: formValues,
    });
  }
  savePaymentInforMationWithoutAuth(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/payment/save_payment_details`,
      data: formValues,
    });
  }
  checkPaymentStatus(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/checkPurchaseValidation`,
      data: formValues,
    });
  }
  getCategoryListV2() {
    return api({
      method: "get",
      // url: `${APPCONFIG.API_URL}auth/v1/category-list`,
      url: `${APPCONFIG.API_URL}auth/v1/filter-category-list`,
    });
  }
  getCalendarServices() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}auth/v1/get_calendar_services`,
      // data: formValues,
    });
  }
  saveUerService(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/save_user_service`,
      data: formValues,
      config,
    });
  }
  getDirectoryCategoryV1() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/category`,
      // data: formValues,
    });
  }
  saveService(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/save_user_service/${Http.WORK_SPACE()}`,
      // }api/save_user_availability/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  getUserService() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/get_user_service`,
    });
  }
  deleteService(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/delete_service`,
      data: formValues,
    });
  }
  getSingleService(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/v1/get_single_service`,
      data: formValues,
    });
  }
  updateDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/save_calendar_details`,
      data: formValues,
    });
  }
  serviceAssessmentAnswer(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/service-assessment-answer`,
      data: formValues,
    });
  }
  getUserByUserId(slug) {
    return api({
      method: "get",
      url: `${APPCONFIG.API_URL}auth/get-user-details/${slug}`,
    });
  }

  saveServiceRecommendForm(formValues, config) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/save-service-recommend-form`,
      data: formValues,
      config,
    });
  }

  saveServiceReviewForm(formValues, config) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/save-service-review-form`,
      data: formValues,
      config,
    });
  }
  getBookingReview(slug) {
    return api({
      method: "get",
      url: `${APPCONFIG.API_URL}auth/get-booking-review/${slug}`,
    });
  }
}

export default new MyYuukke();
