import api from "./BaseAPI.js";
import * as APPCONFIG from "../config/app-config.js";
import auth from "./AuthAPI.js";
import Http from "./Http.js";

import { CHAT_URL } from "../config/app-config";
import axios from "axios";

let CACHE_USER_KP = {};
let CACHE_API_USER_KP = {};
class ProfileService {
  //getAllCountry
  getAllCountry(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAllCountry`,
      data: formValues,
    });
  }
  getWorkExperienceDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getWorkExperience`,
      data: formValues,
    });
  }
  editWorkExperienceList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateWorkExperience`,
      data: formValues,
    });
  }

  sendCustomNotification(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/send_custom_notification`,
      data: formValues,
    });
  }

  saveWorkExperienceDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveWorkExperience`,
      data: formValues,
    });
  }
  //get job request list
  getJobRequestList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getJobRequest`,
      data: formValues,
    });
  }

  getJobRequestByFilter(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/getAskHelpDetailsByType/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }

  //create new user
  createUser(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createnewUser`,
      data: formValues,
    });
  }
  csvCreateUser(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/csvNewUserList`,
      data: formValues,
    });
  }
  getToken() {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAuthToken`,
    });
  }

  updateUser(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/user/update`,
      data: JSON.stringify(formValues),
    });
  }

  saveUser(formValues, config) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/saveuserdetail`,
      data: formValues,
      config,
    });
  }

  updateUserProfile(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateUserProfile`,
      data: formValues,
    });
  }

  settingsSave(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/userSettingSave`,
      data: formValues,
      config,
    });
  }
  updatePhoto(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateUserPhoto`,
      data: formValues,
      config,
    });
  }
  updateCoverPhoto(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateUserCoverPhoto`,
      data: formValues,
      config,
    });
  }

  /**** FRD KNOWLEDGE POINT CACHE & API  */

  setKPCache(formValues, response) {
    CACHE_USER_KP[formValues.userId] = response;
  }

  getKPCache(formValues) {
    if (CACHE_USER_KP[formValues.userId]) {
      return CACHE_USER_KP[formValues.userId];
    } else {
      return null;
    }
  }

  setAPIHitKPCache(formValues, response) {
    CACHE_API_USER_KP[formValues.userId] = response;
  }

  getAPIHitKPCache(formValues) {
    if (CACHE_API_USER_KP[formValues.userId]) {
      return CACHE_API_USER_KP[formValues.userId];
    } else {
      return null;
    }
  }

  getKnowledgePointByUser(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getSumKnowledgePointByUser`,
      data: formValues,
    });
  }

  /**** FRD KNOWLEDGE POINT CACHE & API  */

  //* NON AUTH *//

  getallLanguage() {
    return api({
      method: "get",
      url: `${APPCONFIG.API_URL}auth/getallLanguage`,
    });
  }

  getallHobbies() {
    return api({
      method: "get",
      url: `${APPCONFIG.API_URL}auth/getallHobbies`,
    });
  }
  getallSubjects(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/getallsubject`,
      data: formValues,
    });
  }
  getallClass() {
    return api({
      method: "get",
      url: `${APPCONFIG.API_URL}auth/getClassList`,
    });
  }

  getAllGrades() {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAllGrades`,
    });
  }

  getAllIndustry = async (formValues) => {
    return api({
      method: "get",
      url: `${APPCONFIG.API_URL}api/v1/industry/getAll`,
      data: formValues,
    });
  };

  getAreaOfInterest(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAreaOfInterest`,
      data: formValues,
    });
  }

  getAllRegion() {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAllRegion`,
    });
  }

  getTrackAndCurriculumData(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getCurriculumAndTrack`,
      data: formValues,
    });
  }
  checkUserTag(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/checkUserTag`,
      data: formValues,
    });
  }

  getAllCurriculum() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAllCurriculum`,
    });
  }

  getGradeByCurriculumById(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getGradeByCurriculumById`,
      data: JSON.stringify(formValues),
    });
  }

  getGradeByProgramById(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getGradeByProgramById`,
      data: JSON.stringify(formValues),
    });
  }

  getallNotifications(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/getallNotificationbyid`,
      url: `${
        APPCONFIG.API_URL
      }api/v1/notification/getAllWithoutPagination/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }

  deleteNotification(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deleteNotification`,
      data: formValues,
    });
  }

  deleteAllNotification(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/notifiDesableByUser`,
      data: formValues,
    });
  }

  getBase64String(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getInterestString`,
      data: formValues,
    });
  }

  getAllReminders(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getRemaindersByUser`,
      data: formValues,
    });
  }

  saveContactSupport(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/contactSupport`,
      data: formValues,
      config,
    });
  }

  getUsercoverPhoto(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getUsercoverPhoto`,
      data: JSON.stringify(formValues),
    });
  }
  getAllBadges(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAllBadges`,
      data: JSON.stringify(formValues),
    });
  }

  getRecentlyAddedBadges(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getBadgesListByUser`,
      data: JSON.stringify(formValues),
    });
  }

  getBadgesPointsHistory(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v2/points/get`,
      // url: `${APPCONFIG.API_URL}api/getMypointList`,
      data: JSON.stringify(formValues),
    });
  }
  SaveLastlogin(user) {
    console.log("SaveLastlogin Called");
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/SaveUserLastLogin`,
      data: JSON.stringify(user),
    });
  }

  saveCourseSupport(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveCourseSupport`,
      data: formValues,
    });
  }
  getAllRequestHelpReplyList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAllRequestHelpReplyList`,
      data: formValues,
    });
  }

  instantMeetingAction(data) {
    return axios.post(`${CHAT_URL}api/message/`, data);
  }
  globuleMeetingAction(data) {
    return axios.post(`${CHAT_URL}api/message/globuleEvent`, data);
  }
  updateMeeting(data) {
    return axios.put(`${CHAT_URL}api/message/`, data);
  }
  mentorMeeting(data) {
    return axios.post(`${CHAT_URL}api/message/groupCall`, data);
  }
  custom_delete_notification(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/custom_delete_notification`,
      data: formValues,
    });
  }

  getSingleCalendar(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getSingleCalendar`,
      data: formValues,
    });
  }
  // getUserInfo(formValues) {

  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/getUserInfo`,
  //         data: formValues
  //     })
  // }
  // updateNotification(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/updateNotification`,
  //         data: formValues
  //     })
  // }
  // getAllInterest(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/getAllInterest`,
  //         data: formValues
  //     })
  // }
  // saveTodos(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/saveTodo`,
  //         data: formValues
  //     })
  // }
  // getallToDoList(formValues) {
  //         return auth({
  //             method: 'post',
  //             headers: Http.AUTH_HEADERS(),
  //             url: `${APPCONFIG.API_URL}api/getallTodobyid`,
  //             data: formValues
  //         })
  //     }
  // deleteTodo(formValues) {
  //   return auth({
  //     method: 'delete',
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/deleteTodo`,
  //     data: formValues
  //   })
  // }
  // deleteTodo(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/deleteTodo`,
  //         data: formValues
  //     })
  // }
  // updateTodo(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/updateTodo`,
  //         data: JSON.stringify(formValues)
  //     })
  // }
  // updateTodoText(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/updateTodoText`,
  //         data: JSON.stringify(formValues)
  //     })
  // }
  // saveInterest(formValues, config) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/saveInterest`,
  //         data: formValues,
  //         config
  //     })
  //}

  // getAllReviews(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/getReviews`,
  //         data: formValues
  //     })
  // }

  // saveContactSupport(formValues,config) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/contactSupport`,
  //         data: formValues,config
  //     })
  // }
  //     saveUser(formValues)
  // {
  //   return api({
  //     method: 'post',
  //     url: `${APPCONFIG.API_URL}api/saveuserdetail`,
  //     data: JSON.stringify(formValues)
  //   })

  // }
  // getAllCompetencies(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/getCompetencyLike`,
  //         data: JSON.stringify(formValues)
  //     })
  // }
  // getFirstLoginDetailsUpdate(formValues, config) {
  //     return api({
  //         method: 'post',
  //         url: `${APPCONFIG.API_URL}auth/getFirstLoginDetailsUpdate`,
  //         data: formValues,
  //         config
  //     })
  // }
  // getFirstLoginDetails(formValues) {
  //         return auth({
  //             method: 'post',
  //             headers: Http.AUTH_HEADERS(),
  //             url: `${APPCONFIG.API_URL}api/getalluserdetailbyid`,
  //             data: JSON.stringify(formValues)
  //         })
  //     }
  // updateUser(formValues) {

  //   return auth({
  //     method: 'post',
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/updateuserdetail`,
  //     data: formValues
  //   })
  // }
  // saveProfileImage(formValues, config) {

  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/adduserphoto`,
  //         data: formValues,
  //         config
  //     })
  // }

  // getKnowledgePointByUser(formValues)
  // {
  //       return auth({
  //     method: 'post',
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/getKnowledgePointByUser`,
  //     data: formValues
  //   })
  // }
  //* NON AUTH *//

  // getWelcomeOnce(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/userProfileUpdate`,
  //         data: formValues
  //     })
  // }
  // getClassroomOnce(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/classRoomUpdate`,
  //         data: formValues
  //     })
  // }
  // getallactivityLogData(formValues) {
  //         return auth({
  //             method: 'post',
  //             headers: Http.AUTH_HEADERS(),
  //             url: `${APPCONFIG.API_URL}api/getActivityByUser`,
  //             data: formValues
  //         })
  //     }
  // deleteNotification(formValues) {
  //   return auth({
  //     method: 'delete',
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/deleteNotification`,
  //     data: formValues
  //   })
  // }
  editGroupsEvents(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getallSubjectByid`,
      data: JSON.stringify(formValues),
    });
  }
  editGroupsEventsV2(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAllSubjectByIdV2`,
      data: JSON.stringify(formValues),
    });
  }

  saveEducationDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveEducationDetails`,
      data: JSON.stringify(formValues),
    });
  }

  saveProfession(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveProfession`,
      data: formValues,
    });
  }

  saveSkillDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveSkillsDetails`,
      data: formValues,
    });
  }

  getEducationDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getEducationDetails`,
      data: formValues,
    });
  }

  getSkillsDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getSkillsDetails`,
      data: formValues,
    });
  }

  getProfession(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getProfessionDetails`,
      data: formValues,
    });
  }

  editEducationList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateEducationDetails`,
      data: formValues,
    });
  }
  editSkillsList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateSkillsDetails`,
      data: formValues,
    });
  }

  getJobRequestByUserId(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getJobRequestByUserId`,
      data: formValues,
    });
  }

  storePrivacySettings(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/savePriacySettings`,
      data: formValues,
    });
  }

  getPrivacySetting(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getPriacySettings`,
      data: formValues,
    });
  }
  getJobRequestById(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getJobRequestByJobId`,
      data: formValues,
    });
  }

  getProfessionDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getProfessionDetail`,
      data: formValues,
    });
  }

  getExpectationsInterestDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getExpectationsInterestDetails`,
      data: formValues,
    });
  }

  getExpectationsHelpDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getExpectationsHelpDetails`,
      data: formValues,
    });
  }
  saveUserExpectation(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveUserExpectation`,
      data: formValues,
    });
  }
  getUserExpectation(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getUserExpectation`,
      data: formValues,
    });
  }
  updateUserExpectation(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateUserExpectation`,
      data: formValues,
    });
  }
  getAllTagLists(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAllTagList`,
      data: formValues,
    });
  }
  getPostOverall(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getPostOverall`,
      data: formValues,
    });
  }

  deleteEducationDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deleteEducationDetails`,
      data: formValues,
    });
  }
  deleteSkillDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deleteSkillDetails`,
      data: formValues,
    });
  }
  deleteWorkExperienceDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deleteExperienceDetails`,
      data: formValues,
    });
  }

  getAllCategoryId(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAllGroupCategory`,
      data: formValues,
    });
  }
  getSingleChallenge(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getSingleChallenge`,
      data: JSON.stringify(formValues),
    });
  }
  getAllProofType(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAllProofType`,
      data: formValues,
    });
  }

  getAllUserPoints(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAllUserPoints`,
      data: formValues,
    });
  }
  userPersonalLanguageSave(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/userPersonalLanguageSave`,
      data: formValues,
      config,
    });
  }
  getJobRequestByUserIdV2(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/getJobRequestByUserIdV3/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  admin_resource_post_byid(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/admin_resource_post_byid`,
      data: JSON.stringify(formValues),
    });
  }
  getTourStatus(formValues) {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getUserFirstTimeLoginTour`,
      data: JSON.stringify(formValues),
    });
  }
  // getAllCountryPhoneCode() {
  //   return auth({
  //     method: "get",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/getPhoneCodes`,
  //   });
  // }
  saveUserFirstTimeLoginTour(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveUserFirstTimeLoginTour`,
      data: formValues,
    });
  }
  getAllCountryPhoneCode = async () => {
    return api({
      method: "get",
      url: `${APPCONFIG.API_URL}api/getPhoneCodesv2`,
    });
    // const response = await axios.get(`${APPCONFIG.API_URL}api/getPhoneCodesv2`);
    // return response;
  };
  getAdminCountryList = async () => {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/admin_country_list`,
    });
  };
  saveFirstTimeAskHelpEntry() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveFirstTimeAskHelpEntry`,
    });
  }
  getAllNotSawAskHelpListCount() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/getAllNotSawAskHelpListCount/${Http.WORK_SPACE()}`,
      // url: `${APPCONFIG.API_URL}api/v1/ask_help/count/${Http.WORK_SPACE()}`,
    });
  }
  getAllNotSawAskHelpList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/getAllNotSawAskHelpListv2/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  userSawAskHelpEntrySave(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/userSawAskHelpEntrySave`,
      data: formValues,
    });
  }
  default_workspace_change(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/default_workspace_change`,
      data: formValues,
    });
  }
  getApplicationRequirements(formValues) {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getApplicationRequirements`,
      data: formValues,
    });
  }
  sellerRequest(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/sellerRegistration`,
      data: formValues,
    });
  }
  askHelpReplyDelete(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/askHelpReply/delete`,
      data: formValues,
    });
  }
  reportAction(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/report/posts/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  subSpecialization = async (formValues) => {
    return api({
      method: "get",
      url: `${APPCONFIG.API_URL}api/v2/subSpecialisation/getAll`,
      data: formValues,
    });
  };
  subIndustry = async (formValues) => {
    return api({
      method: "get",
      url: `${APPCONFIG.API_URL}api/v2/subIndustry/getAll`,
      data: formValues,
    });
  };
  mentorSpecialization(formValues) {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/v1/mentor/getSpecialization/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  mentorIndustry(formValues) {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/mentor/getIndustry/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  getMentorList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/mentor/filter/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  getFriendsListBasedWorkSpace(formValues) {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/user/friends/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  mentorProfileUpdate = async (formValues, config) => {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/v1/mentor-profile/update`,
      data: formValues,
      config,
    });
  };
  mentorCreate = async (formData, config) => {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}api/v1/mentor/registration`,
      data: formData,
      config,
    });
  };
  directoryRegister = async (formData, config) => {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/v1/directory/registration`,
      data: formData,
      config,
    });
  };
  eventRegister = async (formData) => {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/v1/calendar/eventRegsiter`,
      data: formData,
    });
  };
  saveImageFile(formValues, config) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/storeMediaTypeFile`,
      data: formValues,
      config,
    });
  }
  userProfileDetailsUpdated(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/update_user_profile`,
      data: formValues,
    });
  }
  sendUserOtp(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/send-user-otp`,
      data: formValues,
    });
  }
  sendOtp(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/v1/send-otp`,
      data: formValues,
    });
  }
  validateUserOtp(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/v1/validate-otp`,
      data: formValues,
    });
  }
  validatePhoneNumber(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/v1/phone-number-validate`,
      data: formValues,
    });
  }
  directoryAndServiceRegister = async (formData, config) => {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/v1/save-form-directory-user`,
      data: formData,
      config,
    });
  };
  getUnAuthDirectoryDetails(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/v1/send-otp`,
      data: formValues,
    });
  }
  getSingleUnAuthDirectory(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/v1/directory-service/get-single`,
      data: formValues,
    });
  }
  saveEnquiry(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/v1/save-enquiry`,
      data: formValues,
    });
  }
}

export default new ProfileService();
