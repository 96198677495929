import React, { useEffect, useState } from "react";
import { API_URL } from "../../config/app-config.js";
import dummyimage from "../../img/default_avatar.jpg";
import yuukke from "../../images/SocialMediaGif/yuukke-white.png";
import "../Community/calendar.css";
import MyYuukke from "../../api/MyYuukke.js";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { GoBriefcase } from "react-icons/go";
import Swal from "sweetalert2";
import faceBook from "../../images/SocialMediaGif/Facebook.png";
import instagram from "../../images/SocialMediaGif/instagram.png";
import linkedIn from "../../images/SocialMediaGif/Linkedin.png";
import CheckBox from "../../components/CheckBox.jsx";
import { withRouter } from "react-router";
import { decryptData } from "../../utils.js";
import StepperFileOption from "../Wizard/StepperFileOption.jsx";
import ImagePreview from "../../components/ImagePreview.jsx";

// let socialMedia=[
//   {name:"faceBook",image:faceBook},{name:"instagram",image:instagram},{name:"linkedIn",image:linkedIn}
//     ]

const ServiceRecommend = (props) => {
  const [state, setState] = useState({
    bookingPage: false,
    slots: [],
    availableSlot: [],
    activeIndexData: null,
    selectedSlot: null,
    slotActiveIndex: null,
    loader: true,
    userDetails: null,
    userSlots: [],
    bookingCount: null,
    detailsPage: false,
    name: "",
    email: "",
    phoneNumber: "",
    description: "",
    bio: "",
    type: "",
    singleDate: new Date(),
    bookedSlot: [],
    paymentPage: false,
    paymentId: null,
    dayId: null,
    serviceList: [],
    serviceId: null,
    serviceAmount: 0,
    minutes: 0,
    appointmentValue: "",
    assessmentForm: false,
    assessmentQuestions: [],
    assessmentNeed: false,
    assessmentAnswer: [],
    socialMedia: [],
    serviceDetails: null,
    language: [],
    profession: "",
    permissionToUse: true,
    companyName: "",
    socialMediaLink: "",
    mediaFiles: "",
  });

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const calendarData = query.get("id");
  useEffect(() => {
    var main = document.getElementById("base");
    main.style.backgroundImage = `linear-gradient(205deg, #000940 0%, #a00030 100%)`;
    if (!calendarData) {
      invalid();
      return;
    }

    const dcrypt = decryptData(calendarData);
    const data = dcrypt.split("&");
    // console.log("dcrypt", data);
    if (data.length > 1) {
      console.log("data", data);
      getSlotDetails(data);
    } else {
      invalid();
    }
    // getSlotDetails([814]);
  }, []);
  const invalid = () => {
    setState((previous) => ({
      ...previous,
      userDetails: null,
      loader: false,
    }));
    toast.error("Invalid link !,please check link");
  };

  const getSlotDetails = (data) => {
    console.log("data", data);
    MyYuukke.getUserByUserId(data[0])
      .then((response) => {
        console.log("response", response);
        let result = response.data.data.userDetails;
        let socialMedia = [];
        if (result.faceBook) {
          socialMedia.push({
            link: result.faceBook,
            icon: faceBook,
          });
        }
        if (result.instagram) {
          socialMedia.push({
            link: result.instagram,
            icon: instagram,
          });
        }
        if (result.linkedIn) {
          socialMedia.push({
            link: result.linkedIn,
            icon: linkedIn,
          });
        }

        setState((previous) => ({
          ...previous,
          userDetails: response.data.data.userDetails,
          loader: false,
          socialMedia: socialMedia,
          formUserId: data[0],
        }));
        // updateState("userDetails", response.data.data.userDetails);
      })
      .catch((e) => {
        toast.error("Slot not present , please check url");
        updateState("loader", false);
        console.log("Error", e);
      });
  };

  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const uploadDetails = (e) => {
    e.preventDefault();
    updateState("loader", true);
    const formData = new FormData();

    // let data = {
    //   type: "recommend",
    //   userName: state.name,
    //   email: state.email,
    //   description: state.description,
    //   permission: state.permissionToUse,
    //   formUserId: state.formUserId,
    // };

    if (state.profession) {
      formData.append("profession", state.profession);
    }
    if (state.companyName) {
      formData.append("company", state.companyName);
    }
    if (state.mediaFiles) {
      formData.append("mediaFile", state.mediaFiles);
    }
    if (state.socialMediaLink) {
      formData.append("socialMediaLink", state.socialMediaLink);
    }

    formData.append("type", "recommend");
    formData.append("userName", state.name);
    formData.append("email", state.email);
    formData.append("description", state.description);
    formData.append("permission", state.permissionToUse);
    formData.append("formUserId", state.formUserId);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    MyYuukke.saveServiceRecommendForm(formData, config)
      .then((response) => {
        console.log("resp", response);
        Swal.fire({
          icon: "success",
          title:
            "Thank you for your Recommending US ! Your recommendation adds a great value to our service.",
          showConfirmButton: true,
        })
          .then((res) => {
            props.history.push("/login");
          })
          .catch((e) => {
            props.history.push("/login");
          });
      })
      .catch((e) => {
        console.log("e", e);
        toast.error("Something went wrong !,Please try latter");
        updateState("loader", false);
      });
  };

  console.log("state", state);
  return (
    <div className="calender-vcard flex-wrap position-relative justify-content-center">
      {state.loader ? (
        <div className="d-flex justify-content-center p-5 align-items-center w-100">
          <div class="spinner-border text-white" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : state.userDetails ? (
        <div className="login-options-opt flex-row m-5 justify-content-center align-items-start flex-wrap">
          <section
            className="position-relative login-options-opt justify-content-centre align-items-center"
            style={{
              minWidth: window.matchMedia("(max-width:739px)").matches
                ? "auto"
                : "30rem",
              gap: "0",
            }}
          >
            <img
              style={{ width: "7rem", height: "7rem" }}
              className="rounded-circle  mt-5 "
              src={
                state.userDetails?.showProfileImage
                  ? API_URL +
                    "api/getUserPhoto/" +
                    state.userDetails.userId +
                    "/" +
                    "name"
                  : yuukke
              }
              alt="user"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = dummyimage;
              }}
            />

            <div className="d-flex flex-column p-3 w-100 justify-content-centre align-items-center">
              <h3 className="font-weight-bold mt-4 text-white text-capitalize">
                {state.userDetails.userName}
              </h3>
              <ul className="d-flex g-1 flex-wrap l-none justify-content-centre">
                {state.socialMedia.map((item, i) => (
                  <li
                    className="mt-2"
                    style={{ height: "2rem", width: "2rem" }}
                    key={i}
                  >
                    <a href={item.link}>
                      <img src={item.icon} className="h-100 w-100" alt="img" />
                    </a>
                  </li>
                ))}
              </ul>
              <p className="f-14 text-white align-center font-weight-bold">
                <GoBriefcase className="mr-2 svg-icons text-white  " />
                {state.userDetails?.profession?.professionName}
              </p>
            </div>
            <p className="f-14 align-center font-weight-bold  mt-auto text-white ml-3">
              Powered by{" "}
              <a
                href="https://yuukke.com/"
                className="text-white  ml-1"
                style={{ textDecoration: "underline" }}
              >
                Yuukke
              </a>
            </p>
          </section>
          <section className="d-flex flex-column p-3 card text-dark">
            <h4 className="font-weight-bold text-black mb-3 mt-2">
              WOULD TO LIKE TO RECOMMEND US ?
            </h4>
            <p className="service-form-heading">
              Your recommendation means the world to us—if you're happy with our
              service, please spread the word!
            </p>
            <form
              onSubmit={uploadDetails}
              className="w-100 p-3 d-flex flex-column font-weight-bold "
              style={{ fontSize: "12px" }}
            >
              <label className="mt-2 mr-auto label-v2">Name *</label>
              <input
                style={{ caretColor: "black" }}
                className="input-text-input text-dark"
                value={state.name}
                type="text"
                placeholder="Enter name"
                required={true}
                onChange={(e) => updateState("name", e.target.value)}
              />
              <label className="mt-2 mr-auto label-v2">Email *</label>
              <input
                style={{ caretColor: "black" }}
                className="input-text-input text-dark"
                value={state.email}
                type="email"
                placeholder="Enter email"
                required={true}
                onChange={(e) => updateState("email", e.target.value)}
              />
              <label className="mt-2 mr-auto label-v2">
                Social Media Link (LinkedIn or Instagram or FaceBook) *
              </label>
              <input
                style={{ caretColor: "black" }}
                className="input-text-input text-dark"
                value={state.socialMediaLink}
                type="url"
                placeholder="Enter here"
                required={true}
                onChange={(e) => updateState("socialMediaLink", e.target.value)}
              />
              <label className="mt-2 mr-auto label-v2">Your Profession</label>
              <input
                style={{ caretColor: "black" }}
                className="input-text-input text-dark"
                value={state.profession}
                type="profession"
                placeholder="Enter profession"
                onChange={(e) => updateState("profession", e.target.value)}
              />
              <label className="mt-2 mr-auto label-v2">
                Company Name (if applicable)
              </label>
              <input
                style={{ caretColor: "black" }}
                className="input-text-input text-dark"
                value={state.companyName}
                type="companyName"
                placeholder="Enter CompanyName"
                onChange={(e) => updateState("companyName", e.target.value)}
              />

              <label className="mt-2 mr-auto label-v2">
                Share a few words on how our service has helped you or your
                business? *
              </label>
              <textarea
                style={{ caretColor: "black", minHeight: "5rem" }}
                className="input-text-input text-dark p-2"
                value={state.description}
                type="text"
                required={true}
                onChange={(e) => updateState("description", e.target.value)}
              />
              <label className="label-v2 mt-3 mr-auto">
                Permission to Use Testimonial:{" "}
              </label>
              <CheckBox
                value={state.permissionToUse}
                onChange={(e) => updateState("permissionToUse", e)}
                label={
                  "Yes, I give permission to use my testimonial in marketing materials."
                }
                color={"text-secondary"}
              />
              <CheckBox
                value={!state.permissionToUse}
                onChange={(e) =>
                  updateState("permissionToUse", !state.permissionToUse)
                }
                label={"No, I prefer to keep my testimonial confidential."}
                color={"text-secondary"}
              />
              <label className="label-v2 mt-3 mr-auto mb-2">
                Would you like to share your photo
              </label>
              {state.mediaFiles ? (
                <ImagePreview
                  apiLogo={false}
                  file={state.mediaFiles}
                  removeFiles={() => {
                    setState((previous) => ({
                      ...previous,
                      mediaFiles: null,
                    }));
                  }}
                />
              ) : (
                <StepperFileOption
                  onUploadSuccess={(file) => {
                    setState((previous) => ({
                      ...previous,
                      mediaFiles: file[0],
                    }));
                  }}
                  acceptedType=".jpg,.jpeg,.png,.svg"
                />
              )}

              <br />
              <button className="  font-weight-bold w-fit-content mt-5 m-auto ">
                Submit
              </button>
            </form>
          </section>
        </div>
      ) : (
        <div className="text-white text-center w-100 mt-5 font-bold">
          Some thing went wrong , please visit{" "}
          <p className="text-secondary">Yuukke</p>
        </div>
      )}
    </div>
  );
};

export default withRouter(ServiceRecommend);
